import { AfterViewInit, Directive, EventEmitter, Input, NgZone, OnInit, Output } from '@angular/core';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { asyncScheduler, fromEvent, observeOn, switchMapTo } from 'rxjs';
import { debounceTime, tap, takeUntil } from 'rxjs/operators';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/public_api';
import { MatLegacyAutocomplete as MatAutocomplete, MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';

const SELECT_ITEM_HEIGHT_EM = 3;

/**
 * Mat select infinite scroll directive for emit when scrolling down.
 * It is necessary for the sub-upload to work on a mobile device.
 * Also saves the current active element when new options are loaded..
 */
@Directive({
  selector: '[protctcMatSelectInfiniteScroll]',
})
@DestroyableComponent()
export class MatAutocompleteInfiniteScrollDirective implements OnInit, AfterViewInit {

  /**
   * Threshold for emit.
   */
  @Input()
  public threshold = '15%';

  /**
   * Debounce time.
   */
  @Input()
  public debounceTime = 150;

  /** Autocomplete trigger. */
  @Input()
  public autocompleteTrigger!: MatAutocompleteTrigger;

  /**
   * Infinity scroll completed.
   */
  @Input()
  public complete = false;

  /**
   * Emit a value when scroll down.
   */
  @Output()
  public readonly infiniteScroll = new EventEmitter<void>();

  private activeItem!: MatOption | null;

  private panel!: Element;

  private thrPx = 0;

  private thrPc = 0;

  private singleOptionHeight = SELECT_ITEM_HEIGHT_EM;

  private scrollTop!: number;

  public constructor(
    private readonly matAutocomplete: MatAutocomplete,
    private readonly ngZone: NgZone,
  ) {
  }

  /**
   * @inheritDoc
   */
  public ngOnInit(): void {
    this.evaluateThreshold();
  }

  /**
   * @inheritDoc
   */
  public ngAfterViewInit(): void {
    this.matAutocomplete.opened.pipe(
      switchMapTo(this.matAutocomplete.options.changes),
      observeOn(asyncScheduler),
      takeUntilDestroy(this),
    ).subscribe(() => {
      if (this.activeItem) {
        this.matAutocomplete._keyManager.setActiveItem(this.activeItem);
        this.matAutocomplete.panel.nativeElement.scrollTop = this.scrollTop;
      }
    });
    this.matAutocomplete.opened.pipe(
      observeOn(asyncScheduler),
      takeUntilDestroy(this),
    ).subscribe(() => {
        this.panel = this.matAutocomplete.panel.nativeElement;
        this.singleOptionHeight = this.getSelectItemHeightPx();
        this.registerScrollListener();
    });
  }

  private evaluateThreshold(): void {
    if (this.threshold.lastIndexOf('%') > -1) {
      this.thrPx = 0;
      this.thrPc = (parseFloat(this.threshold) / 100);

    } else {
      this.thrPx = parseFloat(this.threshold);
      this.thrPc = 0;
    }
  }

  private registerScrollListener(): void {
    fromEvent(this.panel, 'scroll').pipe(
      debounceTime(this.debounceTime),
      tap(event => {
        this.handleScrollEvent(event);
      }),
      takeUntil(this.autocompleteTrigger.panelClosingActions),
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  private handleScrollEvent(event: Event): void {
    this.ngZone.runOutsideAngular(() => {
      if (this.complete) {
        return;
      }
      const countOfRenderedOptions = this.matAutocomplete.options.length;
      const infiniteScrollDistance = this.singleOptionHeight * countOfRenderedOptions;
      const threshold = this.thrPc !== 0 ? (infiniteScrollDistance * this.thrPc) : this.thrPx;

      const scrolledDistance = this.panel.clientHeight + (event.target as HTMLDivElement).scrollTop;
      if ((scrolledDistance + threshold) >= infiniteScrollDistance) {
        this.ngZone.run(() => this.infiniteScroll.emit());
        this.scrollTop = this.matAutocomplete.panel.nativeElement.scrollTop;
        this.activeItem = this.matAutocomplete._keyManager.activeItem;
      }
    });
  }

  private getSelectItemHeightPx(): number {
    return parseFloat(getComputedStyle(this.panel).fontSize) * SELECT_ITEM_HEIGHT_EM;
  }
}
