import { Component, ChangeDetectionStrategy, Input, EventEmitter, Output, ContentChild, TemplateRef } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { MerchantPartnerSortField } from '@protctc/common/core/enums/merchant-partner-sort-field';
import { SortDirection } from '@protctc/common/core/enums/sort-direction';
import { MerchantPartner } from '@protctc/common/core/models/company/merchant-partner';
import { Pagination } from '@protctc/common/core/models/pagination';
import { PaginationData } from '@protctc/common/core/models/pagination-data';
import { SortOptions } from '@protctc/common/core/models/sort-options';
import { masks } from '@protctc/common/core/utils/masks';
import { routePaths } from '@protctc/common/core/utils/route-paths';
import { ViewContext } from '@protctc/common/core/utils/types/view-context';

/** Type for displayed columns. */
export type MerchantDisplayedColumnsType = 'id'
  | 'name'
  | 'ein'
  | 'email'
  | 'fee'
  | 'invoiceCount'
  | 'lastActivity'
  | 'deactivatedAt'
  | 'actions';

/** Merchant partners table. */
@Component({
  selector: 'protctw-merchant-partners-table',
  templateUrl: './merchant-partners-table.component.html',
  styleUrls: ['./merchant-partners-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MerchantPartnersTableComponent {

  /** Merchant partners page. */
  @Input()
  public merchantsPage: Pagination<MerchantPartner> | null = null;

  /** Displayed columns. */
  @Input()
  public displayedColumns: string[] = [];

  /** Sort options. */
  @Input()
  public sortOptions: SortOptions<MerchantPartnerSortField> | null = null;

  /** Is admin invoices table for dashboard. */
  @Input()
  public isAdminInvoicesForDashboard = false;

  /** Empty preview message. */
  @Input()
  public emptyPreviewMessage = '';

  /** Change page emitter. */
  @Output()
  public readonly pageChanged = new EventEmitter<PaginationData>();

  /** Change sort emitter. */
  @Output()
  public readonly sortChanged = new EventEmitter<SortOptions<MerchantPartnerSortField>>();

  /** Monthly subscription emitter. */
  @Output()
  public readonly monthlySubscriptionChanged = new EventEmitter<MerchantPartner>();

  /** Action column. */
  @ContentChild('action', { static: true })
  public readonly actionColumn!: TemplateRef<unknown>;

  /** Route paths. */
  public readonly routePaths = routePaths;

  /** Tooltip text for disabled  checkbox. */
  protected tooltipText = 'Company has not subscribed to the system yet.';

  /** Employer Identification mask. */
  public readonly einMask = masks.taxNumberMask;

  /** Get active sort direction. */
  public get activeSortDirection(): SortDirection {
    return this.sortOptions?.direction as SortDirection ?? SortDirection.Asc;
  }

  /** Get active sort column. */
  public get activeSortColumn(): string {
    return this.sortOptions?.column ?? MerchantPartnerSortField.Default;
  }

  /** Function to track merchant in array.
   * @param _ Index.
   * @param merchant Merchant to track.
   */
  public trackMerchant(_: number, merchant: MerchantPartner): number {
    return merchant.id;
  }

  /**
   * On change page.
   * @param event Page event.
   */
  public onPageChange(event: PageEvent): void {
    this.pageChanged.emit({
      page: event.pageIndex,
      pageSize: event.pageSize,
    });
  }

  /**
   * Change filter's sort configuration.
   * @param sort Sort info.
   */
  public onSortChange(sort: Sort): void {
    const sortDirection = sort.direction || SortDirection.Asc;
    this.sortChanged.emit({
      direction: sortDirection,
      column: sort.active as MerchantPartnerSortField,
    });
  }

  /**
   * Get merchant partner view context to column injection.
   * @param merchant Merchant Partner.
   */
  public getMerchantPartnerViewContext(merchant: MerchantPartner): ViewContext<MerchantPartner> {
    return {
      $implicit: merchant,
    };
  }

  /**
   * On fee click.
   * @param event Event.
   * @param merchant Merchant.
   */
  protected onFeeClick(event: Event, merchant: MerchantPartner): void {
    event.preventDefault();
    if (merchant.hasPaymentInfo) {
      this.monthlySubscriptionChanged.emit(merchant);
    }
  }

}
