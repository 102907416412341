/** Data needed for card details construction. */
export type CardDetailsConstructorData = Omit<CardDetails, 'isEmpty'>;

/** Card details model. */
export class CardDetails {

  /** Card name. */
  public readonly cardName?: string;

  /** Card number with stars. */
  public readonly cardNumberWithStars: string;

  public constructor(data: CardDetailsConstructorData) {
    this.cardName = data.cardName;
    this.cardNumberWithStars = data.cardNumberWithStars;
  }
}
