import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, ContentChild, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, ViewChild } from '@angular/core';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyColumnDef as MatColumnDef, MatLegacyHeaderRowDef as MatHeaderRowDef, MatLegacyNoDataRow as MatNoDataRow, MatLegacyRowDef as MatRowDef, MatLegacyTable as MatTable } from '@angular/material/legacy-table';
import { BehaviorSubject, filter, map, merge, Observable, ReplaySubject, tap } from 'rxjs';

import { assertNonNull } from '../../../core/utils/assert-non-null';
import { Pagination } from '../../../core/models/pagination';
import { PaginationData } from '../../../core/models/pagination-data';

/** Default pagination data. */
export const DEFAULT_PAGINATION_DATA: PaginationData = {
  page: 0,
  pageSize: 10,
};

/** Default page size options. */
export const DEFAULT_PAGE_SIZE_OPTIONS = [5, 10, 50, 100];

/** Table component. */
@DestroyableComponent()
@Component({
  selector: 'protctc-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements OnInit, AfterViewInit, AfterContentInit {

  /** Items that will be displayed on the table. */
  @Input()
  public set page(p: Pagination<T> | null) {
    if (p?.hasItems) {
      this.tableData$.next(p.items);
      this.itemsCount$.next(p.totalCount);
    } else {
      this.tableData$.next([]);
      this.itemsCount$.next(0);
    }
  }

  /** Show first last buttons. */
  @Input()
  public showFirstLastButtons = true;

  /** Displayed columns. */
  @Input()
  public displayedColumns: readonly string[] = [];

  /** Pagination filters. */
  @Input()
  public paginationFilters: PaginationData = DEFAULT_PAGINATION_DATA;

  /** Page size options. */
  @Input()
  public pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS;

  /** Rows are defined at table usage level. */
  @Input()
  public hasCustomRows = false;

  /** Whether page has more items or not.  */
  @Input()
  public set hasNextPage(v: boolean | null) {
    if (v != null) {
      this.hasNextPage$.next(v);
    }
  }

  /** Pagination changes event. */
  @Output()
  public readonly pageChange = new EventEmitter<PaginationData>();

  /** Paginator component. */
  @ViewChild('paginator')
  public paginator?: MatPaginator;

  /** Table element. */
  @ViewChild(MatTable, { static: true })
  public table?: MatTable<T>;

  /** Header rows. */
  @ContentChildren(MatHeaderRowDef)
  public headerRows?: QueryList<MatHeaderRowDef>;

  /** Data row definitions list. */
  @ContentChildren(MatRowDef)
  public rowDefs?: QueryList<MatRowDef<T>>;

  /** Data column definitions. */
  @ContentChildren(MatColumnDef)
  public columnDefs?: QueryList<MatColumnDef>;

  /** No data row. */
  @ContentChild(MatNoDataRow)
  public noDataRow?: MatNoDataRow;

  /** Data for the table. */
  protected readonly tableData$ = new BehaviorSubject<readonly T[]>([]);

  /** Total items count. */
  protected readonly itemsCount$ = new BehaviorSubject<number>(0);

  /** Whether page has more items or not.  */
  private readonly hasNextPage$ = new ReplaySubject<boolean>(1);

  /** Current page number. */
  private pageNumber = this.paginationFilters.page;

  /** Current page size. */
  private pageSize = this.paginationFilters.pageSize;

  /** @inheritdoc */
  public ngOnInit(): void {
    this.pageNumber = this.paginationFilters.page;
    this.pageSize = this.paginationFilters.pageSize;
  }

  /** @inheritdoc */
  public ngAfterViewInit(): void {
    merge(
      this.pageChangeSideEffect(),
      this.setHasNextPageSideEffect(),
    ).pipe(
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /** @inheritdoc */
  public ngAfterContentInit(): void {
    this.columnDefs?.forEach(columnDef => this.table?.addColumnDef(columnDef));
    this.rowDefs?.forEach(rowDef => this.table?.addRowDef(rowDef));
    this.headerRows?.forEach(headerRowDef => this.table?.addHeaderRowDef(headerRowDef));
    this.table?.setNoDataRow(this.noDataRow ?? null);
  }

  private pageChangeSideEffect(): Observable<void> {
    assertNonNull(this.paginator);

    return this.paginator.page.pipe(
      filter(page => this.isPaginationChanged(page)),
      tap(page => this.triggerPageChange(page)),
      map(() => undefined),
    );
  }

  private triggerPageChange(page: PageEvent): void {
    const shouldResetPageNumber = this.pageSize !== page.pageSize;
    const newPageNumber = shouldResetPageNumber ? 0 : page.pageIndex;

    this.pageNumber = newPageNumber;
    this.pageSize = page.pageSize;
    this.pageChange.emit({ page: newPageNumber, pageSize: page.pageSize });
  }

  private isPaginationChanged(page: PageEvent): boolean {
    return this.pageNumber !== page.pageIndex || this.pageSize !== page.pageSize;
  }

  private setHasNextPageSideEffect(): Observable<void> {
    return this.hasNextPage$.pipe(
      tap(hasNextPage => {
        assertNonNull(this.paginator);
        this.paginator.hasNextPage = () => hasNextPage;

        // Should emit some event to paginator to update paginator component.
        this.paginator._changePageSize(this.pageSize);
      }),
      map(() => undefined),
    );
  }
}
