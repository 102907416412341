import { Injectable } from '@angular/core';

import { CardDetails, CardDetailsConstructorData } from '../../models/card-details';

import { CardDetailsDto } from './dto/card-details-dto';
import { IMapper } from './mappers';

/** Card details mapper. */
@Injectable({
  providedIn: 'root',
})
export class CardDetailsMapper implements IMapper<CardDetailsDto, CardDetails> {

  /** @inheritdoc */
  public fromDto(data: CardDetailsDto): CardDetails {
    return new CardDetails(this.mapToCardDetailsConstructorData(data));
  }

  /** @inheritdoc */
  public toDto(data: CardDetails): CardDetailsDto {
    return {
      card_name: data.cardName,
      card_number: data.cardNumberWithStars,
    };
  }

  private mapToCardDetailsConstructorData(data: CardDetailsDto): CardDetailsConstructorData {
    return {
      cardName: data.card_name,
      cardNumberWithStars: data.card_number,
    };
  }
}
