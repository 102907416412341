import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar, MatLegacySnackBarConfig as MatSnackBarConfig, MatLegacySnackBarVerticalPosition as MatSnackBarVerticalPosition } from '@angular/material/legacy-snack-bar';

/** Default notification duration. */
const DEFAULT_DURATION_MS = 2000;

/** Default notification vertical position. */
const DEFAULT_VERTICAL_POSITION: MatSnackBarVerticalPosition = 'top';

/** Default css selector for snackbar container. */
const DEFAULT_SNACKBAR_SELECTOR = 'snackbar';

/** Notification service. */
@Injectable({
  providedIn: 'root',
})
export class NotificationService {

  /** Config. */
  private readonly config: MatSnackBarConfig;

  public constructor(
    private readonly snackBar: MatSnackBar,
  ) {
    this.config = new MatSnackBarConfig();
    this.config.panelClass = DEFAULT_SNACKBAR_SELECTOR;
    this.config.verticalPosition = DEFAULT_VERTICAL_POSITION;
    this.config.duration = DEFAULT_DURATION_MS;
  }

  /**
   * Show notification with custom component.
   * @param notificationComponent Notification component.
   * @param config Config.
   */
  public showNotification<T>(notificationComponent: ComponentType<T>, config = this.config): void {
    this.snackBar.openFromComponent(notificationComponent, config);
  }

  /**
   * Notify a user with a message.
   * @param message Human-readable message.
   */
  public notify(message: string): void {
    this.snackBar.open(message, '', {
      duration: this.config.duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
      panelClass: 'default-snackbar',
    });
  }
}
