
import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { SortDirection } from '@protctc/common/core/enums/sort-direction';
import { UserRole } from '@protctc/common/core/enums/user-role';
import { UsersSortField } from '@protctc/common/core/enums/users-sort-field';
import { Pagination } from '@protctc/common/core/models/pagination';
import { PaginationData } from '@protctc/common/core/models/pagination-data';
import { SortOptions } from '@protctc/common/core/models/sort-options';
import { User } from '@protctc/common/core/models/user';

/** Type for displayed columns. */
export type UserDisplayedColumnsType =
  | 'employeeId'
  | 'name'
  | 'email'
  | 'title'
  | 'role'
  | 'lastActivity'
  | 'actions';

/** Users table. */
@Component({
  selector: 'protctw-users-table',
  templateUrl: './users-table.component.html',
  styleUrls: ['./users-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsersTableComponent {

  /** Users page. */
  @Input()
  public usersPage: Pagination<User> | null = null;

  /** Sort options. */
  @Input()
  public sortOptions: SortOptions<UsersSortField> | null = null;

  /** Displayed columns. */
  @Input()
  public displayedColumns: UserDisplayedColumnsType[] = [];

  /**
   * Is business owner users table or super admin.
   * Change markdown of table.
   */
  @Input()
  public isBusinessOwnerUsersTable = true;

  /** Change page emitter. */
  @Output()
  public readonly page = new EventEmitter<PaginationData>();

  /** Change sort emitter. */
  @Output()
  public readonly sort = new EventEmitter<SortOptions<UsersSortField>>();

  /** User role. */
  public readonly userRole = UserRole;

  /** Represent user role like human-readable string. */
  public readonly toReadableUserRole = UserRole.toReadable;

  /** Get active sort direction. */
  public get activeSortDirection(): SortDirection {
    return this.sortOptions?.direction as SortDirection ?? SortDirection.Asc;
  }

  /** Get active sort column. */
  public get activeSortColumn(): string {
    return this.sortOptions?.column ?? UsersSortField.Default;
  }

  /** Function to track user in array.
   * @param _ Index.
   * @param user User to track.
   */
  public trackUser(_: number, user: User): number {
    return user.id;
  }

  /**
   * On change page.
   * @param event Page event.
   */
  public onPageChange(event: PageEvent): void {
    this.page.emit({
      page: event.pageIndex,
      pageSize: event.pageSize,
    });
  }

  /**
   * Change filter's sort configuration.
   * @param sort Sort info.
   */
  public onSortChange(sort: Sort): void {
    const sortDirection = sort.direction || SortDirection.Asc;
    this.sort.emit({
      direction: sortDirection,
      column: sort.active as UsersSortField,
    });
  }
}
