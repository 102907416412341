import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';

/** Table paginator intl implementation, when we can't get total count items from api. */
@Injectable()
export class TablePaginatorIntlWithoutTotalCount extends MatPaginatorIntl {

  /** @inheritdoc */
  public override getRangeLabel: (page: number, pageSize: number, length: number) => string;

  public constructor() {
    super();
    this.getRangeLabel = (age: number, pageSize: number, length: number) => this.createGetRangeLabel(age, pageSize, length);
  }

  private createGetRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return `0`;
    }

    const startIndex = page * pageSize;
    const endIndex = startIndex + pageSize;

    return `${startIndex + 1} – ${endIndex}`;
  }
}
