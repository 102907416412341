import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { assertNonNull } from '@protctc/common/core/utils/assert-non-null';
import { FileValidation } from '@protctc/common/core/utils/file-validation';
import { controlProviderFor, SimpleValueAccessor } from '@protctc/common/core/utils/value-accessor';
import { AlertDialogComponent } from '@protctc/common/shared/dialogs/alert-dialog/alert-dialog.component';
import { DialogsService } from '@protctc/common/shared/dialogs/dialogs.service';

/** File picker for single type file. */
@Component({
  selector: 'protctc-file-single-picker',
  templateUrl: './file-single-picker.component.html',
  styleUrls: ['../file-picker.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => FileSinglePickerComponent)],
})
export class FileSinglePickerComponent extends SimpleValueAccessor<File> {

  /**
   * For use as unique input id value.
   * Because we can't wrap input from in a label due to styles.
   */
  private static inputIdCounter = 0;

  /** Is file picker for image only. */
  @Input()
  public isImageOnly = false;

  /** Selected file. */
  public selectedFile: File | null = null;

  /** Current unique input id. */
  public readonly currentInputId: string;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    private readonly dialogsService: DialogsService,
  ) {
    super(changeDetectorRef);
    this.currentInputId = `file-picker${FileSinglePickerComponent.inputIdCounter}`;
    FileSinglePickerComponent.inputIdCounter += 1;
  }

  /**
   * Handles file selection.
   * @param fileInput File input.
   */
  public onChange(fileInput: HTMLInputElement): void {
    assertNonNull(fileInput.files);

    this.selectedFile = fileInput.files[0];

    if (this.selectedFile == null) {
      this.controlValue = null;
      return;
    }

    const isFileFormatValid = FileValidation.validateFormat(this.getAvailableFormats(this.isImageOnly))(this.selectedFile) == null;

    if (isFileFormatValid) {
      this.controlValue = this.selectedFile;
      fileInput.value = '';
    } else {
      this.dialogsService.openDialog(AlertDialogComponent, {
        buttonTitle: 'OK',
        message: 'We couldn\'t upload the file, format is not acceptable',
        title: 'Invalid file format',
      });
    }
  }

  /** Handle remove file. */
  public removeFile(): void {
    this.selectedFile = null;
    this.controlValue = this.selectedFile;
  }

  /**
   * Get available formats.
   * @param isImageOnly Is image only flag.
   */
  private getAvailableFormats(isImageOnly: boolean): FileValidation.FileFormat[] {
    return isImageOnly ?
      FileValidation.ALLOWED_IMAGE_FILE_EXTENSIONS :
      FileValidation.FileFormat.toArray();
  }
}
