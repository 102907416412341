import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { Sort } from '@angular/material/sort';
import { SortDirection } from '@protctc/common/core/enums/sort-direction';
import { Pagination } from '@protctc/common/core/models/pagination';
import { PaginationData } from '@protctc/common/core/models/pagination-data';
import { RecurringSchedule } from '@protctc/common/core/models/recurring-schedule/recurring-schedule';
import { RecurringScheduleSortField } from '@protctc/common/core/models/recurring-schedule/recurring-schedule-sort-field';
import { SortOptions } from '@protctc/common/core/models/sort-options';
import { catchAppError } from '@protctc/common/core/rxjs/catch-app-error';
import { NotificationService } from '@protctc/common/core/services/notification.service';
import { RecurringScheduleService } from '@protctc/common/core/services/recurring-schedule.service';
import { DestroyableComponent, takeUntilDestroy } from '@protctc/common/core/utils/destroyable';
import { createTrackByFunction } from '@protctc/common/core/utils/trackby';
import { ConfirmDialogComponent } from '@protctc/common/shared/dialogs/confirm-dialog/confirm-dialog.component';
import { DialogsService } from '@protctc/common/shared/dialogs/dialogs.service';
import { filter, from, tap, throwError } from 'rxjs';

export const RECURRING_INVOICES_INITIAL_PAGINATION_DATA: PaginationData = {
  page: 0,
  pageSize: 10,
};

export const RECURRING_INVOICES_DEFAULT_SORT_OPTIONS: SortOptions<RecurringScheduleSortField> = {
  direction: SortDirection.Desc,
  column: RecurringScheduleSortField.Default,
};

const DEFAULT_PAGE_SIZE_OPTIONS = [10, 20, 30];

/** Type for displayed columns. */
export type RecurringInvoiceColumn =
 | 'id'
 | 'createdDate'
 | 'startDate'
 | 'recurringSchedule'
 | 'nextGenerateDate'
 | 'actions';

/** Recurring invoices table. */
@DestroyableComponent()
@Component({
  selector: 'protctw-recurring-invoices-table',
  templateUrl: './recurring-invoices-table.component.html',
  styleUrls: ['./recurring-invoices-table.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecurringInvoicesTableComponent {

  /** Recurring invoices page. */
  @Input()
  public page: Pagination<RecurringSchedule> | null = null;

  /** Sort options. */
  @Input()
  public sortOptions: SortOptions<RecurringScheduleSortField> | null = null;

  /** Displayed columns. */
  @Input()
  public displayedColumns: RecurringInvoiceColumn[] = [];

  /** Page size options. */
  @Input()
  public pageSizeOptions = DEFAULT_PAGE_SIZE_OPTIONS;

  /** Change page emitter. */
  @Output()
  public readonly pageChanged = new EventEmitter<PaginationData>();

  /** Change sort emitter. */
  @Output()
  public readonly sortChanged = new EventEmitter<SortOptions<RecurringScheduleSortField>>();

  /** Refresh table emitter. */
  @Output()
  public readonly refresh = new EventEmitter<void>();

  private readonly dialogsService = inject(DialogsService);

  private readonly recurringScheduleService = inject(RecurringScheduleService);

  private readonly notificationService = inject(NotificationService);

  /** Track recurring schedule. */
  protected readonly trackRecurringSchedule = createTrackByFunction<RecurringSchedule>('id');

  /**
   * Stop recurring schedule.
   * @param id Recurring schedule ID.
   */
  protected stopRecurringSchedule(id?: RecurringSchedule['id']): void {
    if (!id) {
      return;
    }

    from(this.dialogsService.openDialog(ConfirmDialogComponent, {
      message: `Do you want to stop this recurring schedule?`,
      title: `Stop recurring schedule`,
      isConfirmButtonDanger: true,
      confirmAction: this.recurringScheduleService.stopRecurringSchedule(id).pipe(
        catchAppError(error => {
            this.notificationService.notify('Something went wrong');
            return throwError(() => error);
          }),
      ),
    })).pipe(
      filter(isStopped => isStopped),
      tap(() => this.notificationService.notify('Recurring schedule stopped successfully')),
      tap(() => this.refresh.emit()),
      takeUntilDestroy(this),
    )
      .subscribe();
  }

  /** Get active sort direction. */
  public get activeSortDirection(): SortDirection {
    return this.sortOptions?.direction as SortDirection ?? SortDirection.Asc;
  }

  /** Get active sort column. */
  public get activeSortColumn(): string {
    return this.sortOptions?.column ?? RecurringScheduleSortField.Default;
  }

  /**
   * On change page.
   * @param event Page event.
   */
  protected onPageChange(event: PageEvent): void {
    this.pageChanged.emit({
      page: event.pageIndex,
      pageSize: event.pageSize,
    });
  }

  /**
   * Change filter's sort configuration.
   * @param sort Sort info.
   */
  public onSortChange(sort: Sort): void {
    const sortDirection = sort.direction || SortDirection.Asc;
    this.sortChanged.emit({
      direction: sortDirection,
      column: sort.active as RecurringScheduleSortField,
    });
  }
}
