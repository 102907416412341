import { Injectable } from '@angular/core';
import { PaymentCard } from '@protctc/common/core/models/payment/payment-card';

import { PaymentCardDto } from '../dto/payment/payment-card.dto';
import { IMapper } from '../mappers';

/** Payment card mapper. */
@Injectable({
  providedIn: 'root',
})
export class PaymentCardMapper implements IMapper<PaymentCardDto, PaymentCard> {

  /** @inheritdoc */
  public fromDto(data: PaymentCardDto): PaymentCard {
    return {
      lastFourDigits: data.card_number,
      name: data.card_name ?? '',
    };
  }

  /** @inheritdoc */
  public toDto(data: PaymentCard): PaymentCardDto {

    return {
      card_name: data.name,
      card_number: data.lastFourDigits,
    };
  }
}
