import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { masks } from '@protctc/common/core/utils/masks';
import { controlProviderFor, SimpleValueAccessor } from '@protctc/common/core/utils/value-accessor';

/** For use as default color picker value. */
export const HEX_BLACK_COLOR = '#000000';

/** Color picker component. */
@Component({
  selector: 'protctc-color-picker',
  templateUrl: './color-picker.component.html',
  styleUrls: ['./color-picker.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => ColorPickerComponent)],
})
export class ColorPickerComponent extends SimpleValueAccessor<string> {

  /** Color picker mask. */
  public readonly colorPickerMask = masks.colorPickerMask;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
  ) {
    super(changeDetectorRef);
  }

  /**
   * Handles color's changes.
   * @param event New event.
   */
  public onChange(event: HTMLInputElement): void {
    const { value } = event;
    this.controlValue = value;
  }
}
