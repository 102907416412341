import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { assertNonNull } from '@protctc/common/core/utils/assert-non-null';
import { FileValidation } from '@protctc/common/core/utils/file-validation';
import { controlProviderFor, SimpleValueAccessor } from '@protctc/common/core/utils/value-accessor';
import { AlertDialogComponent } from '@protctc/common/shared/dialogs/alert-dialog/alert-dialog.component';
import { DialogsService } from '@protctc/common/shared/dialogs/dialogs.service';
import { ProtctPlatformService } from '@protctc/common/core/services/protct-platform.service';
import { UploadedDocument } from '@protctc/common/core/models/uploaded-document';

/** Validated file message.  */
interface ValidatedFileMessage {

  /** File. */
  readonly file: File;

  /** Is valid file. */
  readonly isValid: boolean;

  /** Invalid message. */
  readonly invalidMessage: string;

  /** Invalid title message. */
  readonly invalidTitleMessage: string;
}

/** Type for note picker. */
export type NotePickerType = File | UploadedDocument;

export const DEFAULT_MAX_NOTES_COUNT = 5;

/** Note picker. */
@Component({
  selector: 'protctc-note-picker',
  templateUrl: './note-picker.component.html',
  styleUrls: [
    './note-picker.component.css',
    '../file-picker.css',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [controlProviderFor(() => NotePickerComponent)],
})
export class NotePickerComponent extends SimpleValueAccessor<Array<NotePickerType>> {

  /**
   * For use as unique input id value.
   * Because we can't wrap input from in a label due to styles.
   */
  private static inputIdCounter = 0;

  /** Max notes count. */
  @Input()
  public maxNotes = DEFAULT_MAX_NOTES_COUNT;

  /** Allowed file formats. */
  @Input()
  public allowedFileFormats: FileValidation.FileFormat[] = FileValidation.FileFormat.toArray();

  /**
   * Select file. The emit value when clicking on a file item.
   */
  @Output()
  public readonly selectFile = new EventEmitter<NotePickerType>();

  /** Selected files. */
  public selectedFiles: Array<NotePickerType> = [];

  /**
   * Is web.
   */
  public readonly isWeb$ = this.protctPlatformService.isWeb$;

  /** Current unique input id. */
  public readonly currentInputId: string;

  public constructor(
    changeDetectorRef: ChangeDetectorRef,
    private readonly protctPlatformService: ProtctPlatformService,
    private readonly dialogsService: DialogsService,
  ) {
    super(changeDetectorRef);
    this.currentInputId = `note-picker${NotePickerComponent.inputIdCounter}`;
    NotePickerComponent.inputIdCounter += 1;
  }

  /** @inheritdoc */
  public override writeValue(value: NotePickerType[] | null): void {
    this.selectedFiles = value !== null && value.length > 0 ? [...value] : [];
    this.changeDetectorRef.markForCheck();
  }

  /**
   * Handles notes selection.
   * @param fileInput File input.
   */
  public onChange(fileInput: HTMLInputElement): void {
    assertNonNull(fileInput.files);

    const { files } = fileInput;

    if (this.maxNotes && (files.length + this.selectedFiles.length) > this.maxNotes) {
      this.dialogsService.openDialog(AlertDialogComponent, {
        buttonTitle: 'OK',
        message: `Maximum of ${this.maxNotes} attachments are allowed`,
        title: 'File limit exceeded',
      });
      fileInput.value = '';
      return;
    }

    const validatedFilesMessages = this.validateFiles(files);
    for (const validatedFileMessage of validatedFilesMessages) {
      if (validatedFileMessage.isValid) {
        this.selectedFiles = [...this.selectedFiles, validatedFileMessage.file];
      } else {
        this.dialogsService.openDialog(AlertDialogComponent, {
          buttonTitle: 'OK',
          message: validatedFileMessage.invalidMessage,
          title: validatedFileMessage.invalidTitleMessage,
        });
        break;
      }
    }

    this.controlValue = [...this.selectedFiles];
    fileInput.value = '';
  }

  /**
   * Remove file by index.
   * @param i Index.
   */
  public removeFile(i: number): void {
    this.selectedFiles.splice(i, 1);
    this.controlValue = [...this.selectedFiles];
  }

  /**
   * TrackBy function for invoice notes.
   * @param _ Index.
   * @param item Item to track.
   */
  public readonly trackInvoiceNote = (_: number, item: NotePickerType): string => item.name;

  /**
   * Validate files.
   * @param files Files.
   */
  private validateFiles(files: FileList): ValidatedFileMessage[] {
    const validatedFiles: ValidatedFileMessage[] = [];

    for (const file of files) {
      const isFileFormatInvalidErrorMessage = FileValidation.validateFormat(this.allowedFileFormats)(file);
      if (isFileFormatInvalidErrorMessage !== null) {
        validatedFiles.push({
          file,
          isValid: false,
          invalidMessage: isFileFormatInvalidErrorMessage,
          invalidTitleMessage: 'Invalid file format',
        });
        break;
      }

      const isMaxLengthFileNameErrorMessage = FileValidation.validateMaxLength()(file);
      if (isMaxLengthFileNameErrorMessage !== null) {
        validatedFiles.push({
          file,
          isValid: false,
          invalidMessage: isMaxLengthFileNameErrorMessage,
          invalidTitleMessage: 'Invalid max length of file name',
        });
        break;
      }

      if (this.protctPlatformService.currentPlatform$.value === 'mobile') {
        const isMaxSizeFileNameErrorMessage = FileValidation.validateMaxSize(25, 'File size exceeds maximum limit 25 MB.')(file);
        if (isMaxSizeFileNameErrorMessage !== null) {
          validatedFiles.push({
            file,
            isValid: false,
            invalidMessage: isMaxSizeFileNameErrorMessage,
            invalidTitleMessage: 'File size exceeds maximum limit 25 MB.',
          });
          break;
        }
      }

      validatedFiles.push({
        file,
        isValid: true,
        invalidMessage: '',
        invalidTitleMessage: '',
      });
    }

    return validatedFiles;
  }
}
